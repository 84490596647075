<template>
  <div class="partnership-page">
    <div class="about-us">
      <div class="round-right"></div>
      <div class="round-left"></div>
      <div class="container">
        <div class="row about-us-wrapper">
          <div class="col-7">
            <div class="services-block-title">
              Партнерство <span>с нами</span>
            </div>

            <div class="partnership-mobile-back">
              <div class="workers-img" :style="{ 'background-image': `url('./css/images/partnership-back.png')` }">
              </div>
            </div>

            <div class="partnership-program">
              <div class="partnership-program-title">
                Партнерская программа
              </div>
              <div class="partnership-program-desc">
                <div class="partnership-program-left">
                  <span>Приведите клиента и получите от суммы договора.</span>
                  <div class="partnership-program-left-num">
                    10%
                  </div>
                </div>
                <div class="partnership-program-right">
                  <span>Средний чек наших проектов</span>
                  <div class="partnership-program-right-num">
                    $ 15000
                  </div>
                </div>
              </div>
            </div>


            <div class="about-us-contacts">
              <a class="contact-email">
                it@ts-technology.kz
              </a>
              <a class="contact-telegram">
                +7 706 708 0424
              </a>
              <a class="contact-address">
                Республика Казахстан, г. Алматы, пр-т Абая 68/74
              </a>
            </div>
          </div>
          <div class="col-5">
            <div class="workers-img" :style="{ 'background-image': `url('./css/images/partnership-back.png')` }">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container">
        <div class="section-title" style="padding-bottom: 20px">
          Как <span>это работает</span>
        </div>
        <div class="partnership-steps">
          <div class="partnership-step-item box-shadow">
            <div class="step-logo" :style="{ 'background-image': `url('./css/images/partnership-1.svg')` }">

            </div>
            <div class="partnership-step-item-title">
              Заключаем с вами партнерский договор, подписываем NDA;
            </div>
          </div>
          <div class="partnership-step-item box-shadow">
            <div class="step-logo" :style="{ 'background-image': `url('./css/images/partnership-2.svg')` }">

            </div>
            <div class="partnership-step-item-title">
              Вы ведете коммуникацию с клиентом до заключения договора
            </div>
          </div>
          <div class="partnership-step-item box-shadow">
            <div class="step-logo" :style="{ 'background-image': `url('./css/images/partnership-3.svg')` }">

            </div>
            <div class="partnership-step-item-title">
              После заключения договора с клиентом согласно оговоренным условиям, вы получаете комиссионные
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container">
        <div class="section-title" style="padding-bottom: 60px">
          Как <span>присоединиться</span>
        </div>
        <div class="conditions-block">
          <div class="partnership-condition-item box-shadow">
            <div class="condition-item-number">1</div>
            <div class="partnership-condition-logo" :style="{ 'background-image': `url('./css/images/partnership-logo-1.svg')` }">

            </div>
            <div class="partnership-condition-title">
              Подайте заявку на сайте
            </div>
          </div>
          <div class="partnership-condition-item box-shadow">
            <div class="condition-item-number">2</div>
            <div class="partnership-condition-logo" :style="{ 'background-image': `url('./css/images/partnership-logo-2.svg')` }">

            </div>
            <div class="partnership-condition-title">
              Мы свяжемся с вами и обсудим все условия
            </div>
          </div>
          <div class="partnership-condition-item box-shadow">
            <div class="condition-item-number">3</div>
            <div class="partnership-condition-logo" :style="{ 'background-image': `url('./css/images/partnership-logo-3.svg')` }">

            </div>
            <div class="partnership-condition-title">
              Подпишем партнерский договор и NDA
            </div>
          </div>
          <div class="partnership-condition-item box-shadow">
            <div class="condition-item-number">4</div>
            <div class="partnership-condition-logo" :style="{ 'background-image': `url('./css/images/partnership-logo-4.svg')` }">

            </div>
            <div class="partnership-condition-title">
              Отправим все нужные материалы по нашей компании для заключения сделки
            </div>
          </div>
          <div class="partnership-condition-item box-shadow justify-content-around">
            <div class="partnership-logo-nda" :style="{ 'background-image': `url('./css/images/form-logo.svg')` }">

            </div>
            <div class="partnership-condition-title">
              Что такое NDA?
              <div @click="dialogVisible=true">Узнать больше</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container">
        <div class="contacts-section">
          <div class="contacts">
            <div class="contacts-title">
              Связаться <span>с нами</span>
            </div>
            <div class="contacts-desc">
              Свяжитесь с нами удобным для вас способом, или заполните форму и мы напишем вам на почту.
            </div>
            <div class="contacts-item">
              <a class="contact-telegram">
                +7 706 708 0424
              </a>
            </div>
            <div class="contacts-item">
              <a class="contact-email">
                it@ts-technology.kz
              </a>
            </div>

            <div class="requisites">
              <div class="requisites-title">
                Реквизиты
              </div>
              <div class="requisites-text">
                ТОО "TROUBLESHOOTING TECHNOLOGY"
              </div>
              <div class="requisites-text">
                БИН<span>191140015704</span>
              </div>
              <div class="requisites-text">
                ИИК<span>KZ51998BTB0000517245</span>
              </div>
              <div class="requisites-text">
                АО «First Heartland Jýsan Bank»
              </div>
              <div class="requisites-text">
                БИН<span>TSESKZKA</span>
              </div>
            </div>
          </div>

          <div class="email-form">
            <div class="email-form-logo"></div>
            <el-input class="partnership-email-form-fio" placeholder="ФИО" v-model="name"></el-input>
            <div class="email-form-inputs">
              <el-input placeholder="Номер телефона" v-model="phone"></el-input>
              <el-input placeholder="E-mail" v-model="email"></el-input>
            </div>
            <el-input
                class="email-form-textarea"
                type="textarea"
                :rows="8"
                placeholder="Краткое описание запроса"
                v-model="desc">
            </el-input>
            <el-radio-group v-model="radio" class="email-form-status">
              <el-radio label="individual" size="large">Физическое лицо</el-radio>
              <el-radio label="company" size="large">Юридическое лицо</el-radio>
            </el-radio-group>
            <button class="email-form-btn" @click="foo">
              Отправить
            </button>
          </div>

        </div>

        <div class="map-block">
          <div class="contacts-map-title">
            Республика Казахстан, г. Алматы, пр-т Абая 68/74
          </div>
          <div class="map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2906.5964320532607!2d76.90289601496097!3d43.23892028713921!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3883693aeb7dfe15%3A0x19e7c91d09661b35!2z0L_RgC3Rgi4g0JDQsdCw0Y8gNjgvNzQsINCQ0LvQvNCw0YLRiyA1MDA1MDA!5e0!3m2!1sru!2skz!4v1643264170689!5m2!1sru!2skz" allowfullscreen="allowfullscreen" loading="lazy" style="border: 0px; border-radius: 0 0 15px 15px; width: 100%; height: 100%;"></iframe>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
        :visible.sync="dialogVisible"
        width="30%">
      <span class="text-break">Соглашение о неразглашении (англ. Non-disclosure agreement, NDA) — юридический договор, заключённый двумя сторонами с целью взаимного обмена материалами, знаниями или другой информацией с ограничением к ней доступа третьим лицам. Данный тип соглашений служит для предотвращения утечки любой конфиденциальной информации: от коммерческой тайны до персональных данных.</span>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">Закрыть</el-button>
  </span>
    </el-dialog>

  </div>
</template>


<script>

import Vue from "vue";

export default {
  name: 'Partnership',
  data() {
    return {
      dialogVisible: false,
      radio: 'individual',
      name: '',
      phone: '',
      email: '',
      desc: '',
    }
  },
  methods: {
    foo() {
      console.log(this.radio)
    }
  },
}
</script>
<style>

</style>
